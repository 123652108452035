export const blogPageTextStyle = theme=>({
    // font sizes
    '& h1': {
        fontSize: '2.4rem',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(3),
    },
    '&>h2, &>*>h2': {
        fontSize: '1.6rem',
        color: theme.palette.text.primary,
        marginBottom: theme.spacing(3),
    },
    '&>h3, &>*>h3': {
        fontSize: '1.1rem',
        color: theme.palette.text.primary,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    // margins
    '& *+h2': {
        marginTop: theme.spacing(6),
    },
    '& pre': {
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
    },
}) as any